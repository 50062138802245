<template>
    <div class="menu_div">
        <div class="menu_div01">
            <div :class="pageNum === 1 ? 'menu_div01_select menu_div01_home' : 'menu_div01_home'" @click="toPage(1)">Home</div>
            <div :class="pageNum === 2 ? 'menu_div01_select menu_div01_games' : 'menu_div01_games'" @click="toPage(2)">Games</div>
            <div :class="pageNum === 3 ? 'menu_div01_select menu_div01_activity' : 'menu_div01_activity'" @click="toPage(3)">Activity</div>
            <div :class="pageNum === 4 ? 'menu_div01_select menu_div01_earn' : 'menu_div01_earn'" @click="toPage(4)">Earn</div>
            <div :class="pageNum === 5 ? 'menu_div01_select menu_div01_invite' : 'menu_div01_invite'" @click="toPage(5)">Invite</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Menu",
        data() {
            return {
                pageNum: 1
            }
        },
        watch: {},
        created() {
        },
        mounted() {
            this.initPage(1);
        },
        methods: {
            initPage(page) {
                if (sessionStorage.getItem("pageNum")) {
                    this.pageNum = parseInt(sessionStorage.getItem("pageNum"));
                    sessionStorage.setItem("pageNum", this.pageNum);
                } else {
                    this.pageNum = page;
                }
            },
            toPage(page) {
                if (page === 1) {
                    this.$router.push("/home");
                } else if (page === 2) {
                    this.$router.push("/games");
                } else if (page === 3) {
                    this.$router.push("/activity");
                } else if (page === 4) {
                    this.$router.push("/earn");
                } else if (page === 5) {
                    this.$router.push("/invite");

                    // this.$message.warning("Coming soon!");
                    // return;
                } else {
                    this.$message.warning("Coming soon!");
                    return;
                }

                this.pageNum = page;
                sessionStorage.setItem("pageNum", page);
            }
        }
    }
</script>

<style lang="less">
    .menu_div {
        width: 100%;
        height: 100px;
        background: black;

        position: absolute;
        bottom: 0;
        left: 0;
        .menu_div01 {
            height: 100%;
            width: calc(100% - 100px);
            margin-left: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            .menu_div01_home {
                cursor: pointer;
                height: 100%;
                line-height: 100px;
                color: white;
            }
            .menu_div01_games {
                cursor: pointer;
                height: 100%;
                line-height: 100px;
                color: white;
            }
            .menu_div01_activity {
                cursor: pointer;
                height: 100%;
                line-height: 100px;
                color: white;
            }
            .menu_div01_earn {
                cursor: pointer;
                height: 100%;
                line-height: 100px;
                color: white;
            }
            .menu_div01_invite {
                cursor: pointer;
                height: 100%;
                line-height: 100px;
                color: white;
            }
            .menu_div01_select {
                color: #027d93 !important;
            }
        }
    }
</style>
