<template>
    <div class="top_div">
        <div class="top_div01">GamerCenter</div>
        <div v-if="true" class="top_div02">
            <i class="el-icon-s-operation" @click="openTopDrawer()"></i>
        </div>

        <el-drawer
                title="Settings"
                :visible.sync="topDrawer"
                size="60%"
                :with-header="true">
            <div class="top_dra_div">
                <div v-if="true" class="top_dra_div00">
                    <div class="top_dra_div001"><el-avatar icon="el-icon-user-solid"></el-avatar> {{ userId ? userId : 'NULL' }}</div>
                    <div class="top_dra_div002"><span>User Name: </span> {{ userName ? userName : 'USERNAME' }}</div>
                    <div class="top_dra_div002">
                        <span>User Address: </span>
                        {{ userAddress ? cutAccount(userAddress, 8) : 'Please connect the wallet!' }}
                        <i v-show="isBindWallet" class="el-icon-circle-check"></i>
                    </div>
                    <span v-show="false">Raw address: {{ getUserAddress }}</span>
                </div>

                <div v-if="true" class="top_dra_div02">
                    <img src="../../assets/images/qb.png"/>
                    <div class="top_dra_div021">
                        {{ isBindWallet ? 'Unbind' : 'Binding'}}
                    </div>
                    <el-switch
                            v-model="isBindWallet"
                            @change="userBindWallet()"
                            active-color="#027d93"
                            inactive-color="#a7a7a7">
                    </el-switch>
                </div>

                <div v-if="true" class="top_dra_div01" @click="toBindTwitter()">
                    <img src="../../assets/images/twitter_logo.jpg"/>
                    {{ isBinding ? 'Already bound' : 'Bind Twitter'}}
                    <i class="el-icon-link"></i>
                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>
    import request from "../../tools/request";
    import toolUtils from "../../tools/toolUtils";
    import { Vue2 } from 'vue-demi';

    import { useTonAddress } from '@townsquarelabs/ui-vue';

    export default {
        name: "Top",
        components: {},
        data() {
            return {
                topDrawer: false,
                userId: 0,
                userName: "USERNAME",
                userFriendlyAddress: null,
                userAddress: null,
                isBinding: false,
                isBindWallet: false,
            }
        },
        watch: {},
        computed: {
            getUserAddress() {
                const rawAddress = useTonAddress(true);
                this.userAddress = rawAddress.value;
                sessionStorage.setItem("userAddress", this.userAddress);
                // console.log("userAddress: ", this.userAddress);

                const userFriendly = useTonAddress(false);
                this.userFriendlyAddress = userFriendly.value;
                // console.log("userFriendlyAddress: ", this.userFriendlyAddress);

                return this.userFriendlyAddress;
            }
        },
        created() {
            if (Vue2) {
                Vue2.config.ignoredElements.push('x-foo')
            }

            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
                sessionStorage.setItem("userId", this.userId);
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
                sessionStorage.setItem("userName", this.userName);
            }
            if (sessionStorage.getItem("userAddress")) {
                this.userAddress = sessionStorage.getItem("userAddress");
                sessionStorage.setItem("userAddress", this.userAddress);
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
            }
            if (sessionStorage.getItem("userAddress")) {
                this.userAddress = sessionStorage.getItem("userAddress");
            }

            this.getUserInfoById();
            this.getVerifyBindingAndSignIn();
        },
        methods: {
            async getUserInfoById() {
                if (this.userId) {
                    await request.getUserInfoById(this.userId).then((res) => {
                        if (res.data.user_name) {
                            this.userName = res.data.user_name;
                            sessionStorage.setItem("userName", res.data.user_name);
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    });
                }
            },
            async getVerifyBindingAndSignIn() {
                if (this.userId) {
                    await request.getVerifyBindingAndSignIn(this.userId).then((res) => {
                        this.isBinding = res.data.isBinding;
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            toBindTwitter() {
                this.$message.warning("Coming soon!");
                return;
                // if (this.userId && this.isBinding === false) {
                //     window.location.href = "https://task.mintagame.com/#/login?is_binding=true&user_id=" + this.userId;
                // }
            },
            cutAccount(val, num) {
                return val.length > 8 ? toolUtils.cutAccount(val, num) : val;
            },
            openTopDrawer() {
                this.topDrawer = true;

                if (this.userId) {
                    request.getUserInfoById(this.userId).then((res) => {
                        if (res.data) {
                            this.isBindWallet = res.data.user_address ? true : false;
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }

                this.getVerifyBindingAndSignIn();
            },
            async userBindWallet() {
                if (this.userId) {
                    if (this.isBindWallet && !this.userAddress) {
                        this.isBindWallet = !this.isBindWallet;
                        this.$message.warning("Please connect the wallet!");
                        return;
                    }
                    const addressVal = this.isBindWallet ? this.userAddress : null;
                    await request.userBindWallet(this.userId, addressVal).then((res) => {
                        if (res.data) {
                            this.isBindWallet = res.data.user_address ? true : false;
                            this.$message.success("Operation successful!");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.isBindWallet = false;
                    this.$message.warning("Please log in first!");
                }
            }
        }
    }
</script>

<style lang="less">
    .top_div {
        width: 100%;
        height: 100px;
        background: black;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .top_div01 {
            width: auto;
            height: 100%;
            margin-left: 50px;
            background: black;
            color: white;
            font-size: 24px;
            line-height: 100px;
            font-weight: 600;
            text-align: left;
        }
        .top_div02 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;

            margin-right: 50px;
            color: white;
            font-size: 20px;

            img {
                width: 35px;
                margin-top: -2px;
                cursor: pointer;
            }
            i {
                font-size: 25px;
                cursor: pointer;
            }
        }
        .top_dra_div {
            background-color: #131313;
            .top_dra_div00 {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin-bottom: 20px;
                .top_dra_div001 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    color: white;
                    font-size: 24px;
                    font-weight: 600;
                    margin: 20px 0;
                    span {
                        margin: 0 12px;
                    }
                }
                .top_dra_div002 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 14px;
                    line-height: 17px;
                    color: white;
                    margin: 10px 12px;
                    span {
                        font-weight: 600;
                        color: #027d93;
                        margin-right: 10px;
                    }
                    i {
                        font-size: 17px;
                        color: #027d93;
                        margin-left: 10px;
                    }
                }
            }
            .top_dra_div01 {
                height: 55px;
                color: white;
                font-size: 18px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                img {
                    width: 35px;
                    height: 35px;
                    margin: 0 10px;
                }
                i {
                    font-size: 28px;
                    margin-left: 15px;
                }
            }
            .top_dra_div02 {
                height: 55px;
                color: white;
                font-size: 18px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .top_dra_div021 {
                    width: 80px;
                }
                img {
                    width: 30px;
                    height: 30px;
                    margin: 0 13px;
                }
            }
        }
    }

    .el-drawer {
        font-size: 26px !important;
        background-color: black !important;
        .el-drawer__header {
            margin-top: 8px !important;
            color: #ffffff !important;
            .el-drawer__close-btn {
                font-size: 28px !important;
            }
        }
    }
</style>
